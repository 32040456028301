import { Action, UserRole } from '../propTypes';
import { ActionTypes } from '../actions/actionTypes';
import { UserState } from '../propTypes';

const initialState: UserState = {
  loggedUser: { id: 0, name: '', photo: '' },
  currentRole: UserRole.seller,
};

const reducer = (
  state: UserState = initialState,
  action: Action
): UserState => {
  switch (action.type) {
    // Logged user:
    case ActionTypes.SWITCH_USER:
      return { ...state, loggedUser: action.payload };

    case ActionTypes.CHANGE_ROLE:
      return { ...state, currentRole: action.payload };

    default:
      return state;
  }
};

export default reducer;

export enum ActionTypes {
  FETCH_BTC_RATE = 'trades.FETCH_BTC_RATE',
  FETCH_BTC_RATE_ERR = 'trades.FETCH_BTC_RATE_ERR',
  FETCH_TRADES = 'trades.FETCH_TRADES',
  BUY_BTC = 'trades.BUY_BTC',
  RELEASE_BTC = 'trades.RELEASE_BTC',
  FETCH_MESSAGES = 'trades.FETCH_MESSAGES',
  DELETE_TRADE = 'trades.DELETE_TRADE',
  SWITCH_USER = 'trades.SWITCH_USER',
  CHANGE_ROLE = 'trades.CHANGE_ROLE',
  SEND_MESSAGE = 'trades.SEND_MESSAGE',
  LOAD_NEW_MESSAGES = 'trades.LOAD_NEW_MESSAGES',
  SHOW_HIDE_DELETE_MODAL = 'trades.SHOW_HIDE_DELETE_MODAL',
  SET_SELECTED_TRADE = 'trades.SELECTED_TRADE',
}

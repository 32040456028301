import { ActionTypes } from './actions/actionTypes';

// Common
/// Trades
export interface AllTrades {
  id: number;
  buyerId: number;
  sellerId: number;
  amount: number;
  currency: string;
  paymentMethod: string;
  hash: string;
  status: boolean;
  BTCReleased: boolean;
  timestamp: Date;
}

export interface Trade extends AllTrades {
  userName: string;
  userPhoto: string;
  numberOfTrades: number;
  positive: number;
  negative: number;
}

export interface TradesBuyerSeller extends AllTrades {
  buyerName: string;
  buyerPhoto: string;
  buyerNumberOfTrades: number;
  buyerPositive: number;
  buyerNegative: number;

  sellerName: string;
  sellerPhoto: string;
  sellerNumberOfTrades: number;
  sellerPositive: number;
  sellerNegative: number;
}
/// Messages:
export interface MessageBody {
  id: number;
  senderId: number;
  text: string;
  timestamp: Date;
}

export interface ChatMessages {
  id: number;
  tradeId: number;
  messageList: MessageBody[];
}

export interface ConstructNewMessage {
  chatId: number;
  tradeId: number;
  messageBody: MessageBody;
}

export interface newMessageRecieved {
  tradeId: number;
  senderId: number;
}

/// User
export enum SimulateUsers {
  firstUser = 1,
  secondUser = 2,
}

export enum UserRole {
  seller = 'seller',
  buyer = 'buyer',
}

export interface LoggedUser {
  id: number;
  name: string;
  photo: string;
}

// Actions
export interface FetchBTCRate {
  type: ActionTypes.FETCH_BTC_RATE;
  payload: number;
}

export interface FetchBTCRateErr {
  type: ActionTypes.FETCH_BTC_RATE_ERR;
  payload: number;
}

export interface FetchTrades {
  type: ActionTypes.FETCH_TRADES;
  payload: TradesBuyerSeller[];
}
export interface BuyBTC {
  type: ActionTypes.BUY_BTC;
  payload: number;
}

export interface ReleaseBTC {
  type: ActionTypes.RELEASE_BTC;
  payload: number;
}

export interface FetchMessages {
  type: ActionTypes.FETCH_MESSAGES;
  payload: ChatMessages[];
}

export interface DeleteTrade {
  type: ActionTypes.DELETE_TRADE;
  payload: number;
}

export interface SwitchUser {
  type: ActionTypes.SWITCH_USER;
  payload: LoggedUser;
}

export interface ChangeRole {
  type: ActionTypes.CHANGE_ROLE;
  payload: UserRole;
}

export interface SendMessage {
  type: ActionTypes.SEND_MESSAGE;
  payload: ConstructNewMessage;
}

export interface LoadNewMessages {
  type: ActionTypes.LOAD_NEW_MESSAGES;
  payload: number;
}

export interface ShowHideDeleteModal {
  type: ActionTypes.SHOW_HIDE_DELETE_MODAL;
  payload: boolean;
}

export interface SetSelectedTrade {
  type: ActionTypes.SET_SELECTED_TRADE;
  payload: number;
}

export type Action =
  | FetchBTCRate
  | FetchBTCRateErr
  | BuyBTC
  | FetchTrades
  | ReleaseBTC
  | FetchMessages
  | DeleteTrade
  | SwitchUser
  | ChangeRole
  | SendMessage
  | LoadNewMessages
  | ShowHideDeleteModal
  | SetSelectedTrade;

// Components
export interface TradesListProperties {
  BTCRate: number | null;
  userTradesList: Trade[];
  currentRole: UserRole;
  userId: number;
  newMessages: newMessageRecieved[];
  selectedTrade: number;
}

export interface TradesListProps extends TradesListProperties {
  fetchBTCRate: Function;
  fetchTrades: Function;
  fetchMessages: Function;
  loadNewMessages: Function;
  setSelectedTrade: Function;
}

export interface TradeDetailsProps {
  trade: Trade;
  BTCRate: number | null;
  chatMessages: ChatMessages;
  currentRole: UserRole;
  loggedUser: LoggedUser;
  newMessages: newMessageRecieved[];
  showModal: boolean;
}

export interface photoProps {
  photoSrc: string;
  children?: JSX.Element;
}

export interface TradeInfoProps {
  trade: Trade;
  BTCRate: number | null;
  buyBTC: Function;
  releaseBTC: Function;
  currentRole: UserRole;
}

export interface TradeStatusProps {
  status: boolean;
}

export interface ChatTopProps {
  trade: Trade;
  deleteTrade: Function;
  showHideDeleteModal: Function;
  showModal: boolean;
}

export interface ChatBoxProps {
  chatMessages: ChatMessages;
  trade: Trade;
  loggedUser: LoggedUser;
}

export interface DeleteTradeProps {
  tradeId: number;
}

export interface ModalProps {
  title: string;
  content: string;
  actions: any;
  show: boolean;
  showHideDeleteModal: Function;
}

export interface ChatInputProps {
  loggedUserId: number;
  chatMessages: ChatMessages;
  newMessages: newMessageRecieved[];
  sendMessage: Function;
  loadNewMessages: Function;
}

export interface SwitchRoleProperties {
  loggedUser: LoggedUser;
  currentRole: UserRole;
}

export interface SwitchRoleProps extends SwitchRoleProperties {
  switchUser: Function;
  changeRole: Function;
}

export interface MenuItem {
  label: string;
  href: string;
}

// Global State
export interface TradeState {
  BTCRate: number | null;
  tradesList: TradesBuyerSeller[];
  chatMessages: ChatMessages[];
  newMessages: newMessageRecieved[];
  selectedTrade: number;
}

export interface UserState {
  loggedUser: LoggedUser;
  currentRole: UserRole;
}

export interface DeleteModalState {
  show: boolean;
}

export interface State {
  tradesReducer: TradeState;
  userReducer: UserState;
  modalReducer: DeleteModalState;
}

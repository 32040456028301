import React from 'react';
import { ModalProps } from '../../store/trades/propTypes';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { showHideDeleteModal } from '../../store/trades/actions/actions';
import { connect } from 'react-redux';
import TradesText from '../../locals/en/trades.json';

const PopUpModal = (props: ModalProps): JSX.Element => {
  const handleClose = () => props.showHideDeleteModal(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {TradesText.btnClose}
        </Button>
        {props.actions}
      </Modal.Footer>
    </Modal>
  );
};
export default connect(null, { showHideDeleteModal })(PopUpModal);

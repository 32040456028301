import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../history';

import TradesPage from './trades';
import MainHeader from '../components/header/MainHeader';
import SellBitcoinsHeader from '../components/header/SellBitcoinsHeader';

const Pages = (): JSX.Element => {
  return (
    <Router history={history}>
      <MainHeader />
      <SellBitcoinsHeader />

      <Route path="/trades">
        <TradesPage />
      </Route>
    </Router>
  );
};

export default Pages;

import React from 'react';
import { TradeStatusProps } from '../../../store/trades/propTypes';
import TradesText from '../../../locals/en/trades.json';

const TradeStatus = (props: TradeStatusProps): JSX.Element => {
  let textValue: string = TradesText.statusPaid;
  let textStyle: string = 'text-success';

  if (!props.status) {
    textValue = TradesText.statusNotPaid;
    textStyle = 'text-muted';
  }

  return (
    <p className={`font-weight-bold mt-1 text-uppercase ${textStyle}`}>
      {textValue}
    </p>
  );
};

export default TradeStatus;

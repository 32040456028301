import React from 'react';
import {
  State,
  SwitchRoleProps,
  SimulateUsers,
  UserRole,
  SwitchRoleProperties,
} from '../../store/trades/propTypes';
import { connect } from 'react-redux';
import { setLoggedUser, setUserRole } from '../../store/trades/actions/actions';
import Photo from './common/Photo';
import TradesText from '../../locals/en/trades.json';
import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class SwitchRole extends React.Component<SwitchRoleProps> {
  componentDidMount() {
    this.props.switchUser(SimulateUsers.firstUser);
    this.props.changeRole(UserRole.seller);
  }

  switchUserHandler = () => {
    const userId =
      this.props.loggedUser.id === SimulateUsers.firstUser
        ? SimulateUsers.secondUser
        : SimulateUsers.firstUser;

    this.props.switchUser(userId);

    const newRole =
      this.props.currentRole === UserRole.seller
        ? UserRole.buyer
        : UserRole.seller;

    this.props.changeRole(newRole);
  };

  btnText = () =>
    this.props.currentRole === UserRole.seller
      ? TradesText.buyer
      : TradesText.seller;

  render = () => (
    <Row className="pt-2 pb-2 align-items-center h-100">
      <Col md={7} sm={12} className="text-center text-md-left">
        <Photo photoSrc={this.props.loggedUser.photo}>
          <Figure.Caption>
            <span>
              {TradesText.greeting}, {this.props.loggedUser.name}!
            </span>
          </Figure.Caption>
        </Photo>
      </Col>
      <Col md={5} sm={12} className="text-center text-md-left">
        <span className="pr-2">
          {TradesText.roleDesc} <b>{this.props.currentRole}</b>
        </span>
        <Button variant="success" onClick={this.switchUserHandler}>
          {TradesText.switchBtn} {this.btnText()}
        </Button>
      </Col>
    </Row>
  );
}

const mapStateToProps = ({ userReducer }: State): SwitchRoleProperties => ({
  loggedUser: userReducer.loggedUser,
  currentRole: userReducer.currentRole,
});
export default connect(mapStateToProps, {
  switchUser: setLoggedUser,
  changeRole: setUserRole,
})(SwitchRole);

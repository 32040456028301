import React from 'react';
import { ChatTopProps } from '../../../store/trades/propTypes';
import {
  deleteTrade,
  showHideDeleteModal,
} from '../../../store/trades/actions/actions';
import { connect } from 'react-redux';
import PopUpModal from '../../common/PopUpModal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TradesText from '../../../locals/en/trades.json';

const ChatTop = (props: ChatTopProps): JSX.Element => {
  const deleteTradeHandler = () => {
    props.showHideDeleteModal(false);
    props.deleteTrade(props.trade.id);
  };

  const deleteAction = () => (
    <Button onClick={deleteTradeHandler} variant="danger">
      {TradesText.btnDelete}
    </Button>
  );

  const handleShow = () => props.showHideDeleteModal(true);

  const myTrade = props.trade;
  return (
    <Row className="mt-3 ml-2 text-center">
      <Col md={2} sm={2}>
        <Button
          className="rounded-circle"
          variant="secondary"
          onClick={handleShow}
        >
          <i className="fa fa-trash h5 text-center" aria-hidden="true"></i>
        </Button>
      </Col>
      <Col md={8} className="text-muted">
        <h3>{myTrade.paymentMethod}</h3>
        <span>{myTrade.userName} </span>
        <span className="text-success">+{myTrade.positive}</span> /{' '}
        <span className="text-danger">-{myTrade.negative}</span>
      </Col>

      <PopUpModal
        title={TradesText.DeleteTradePopUpTitle}
        content={`${TradesText.DeleteTradePopUpContent}${myTrade.id}`}
        actions={deleteAction()}
        show={props.showModal}
      />
    </Row>
  );
};

export default connect(null, {
  deleteTrade,
  showHideDeleteModal: showHideDeleteModal,
})(ChatTop);

import {
  FetchBTCRate,
  FetchTrades,
  ReleaseBTC,
  FetchMessages,
  ChatMessages,
  DeleteTrade,
  UserRole,
  ChangeRole as SetUserRole,
  SwitchUser as SetLoggedUser,
  BuyBTC,
  SendMessage,
  ConstructNewMessage,
  LoadNewMessages,
  FetchBTCRateErr,
  ShowHideDeleteModal,
  SetSelectedTrade,
} from '../propTypes';
import { ActionTypes } from './actionTypes';
import { Dispatch } from 'redux';
import BTCRate from '../apis/BTCRate';
import users from '../../../dataMock/users.json';
import allTrades from '../../../dataMock/trades.json';
import allMesssages from '../../../dataMock/messages.json';
import { TradesBuyerSeller } from '../propTypes';
import { map, filter } from 'lodash';
import history from '../../../history';

// BTC Rate
export const fetchBTCRate = () => async (dispatch: Dispatch) => {
  try {
    const response = await BTCRate.get<any>('/v1/bpi/currentprice/USD.json');

    dispatch<FetchBTCRate>({
      type: ActionTypes.FETCH_BTC_RATE,
      payload: response.data.bpi.USD.rate.replace(',', ''),
    });
  } catch (error) {
    dispatch<FetchBTCRateErr>({
      type: ActionTypes.FETCH_BTC_RATE_ERR,
      payload: error,
    });
  }
};

// Trades
export const fetchTrades = (): FetchTrades => {
  const createTrade = (trade: any): TradesBuyerSeller => {
    const buyer = filter(users.users, { id: trade.buyerId })[0];
    const seller = filter(users.users, { id: trade.sellerId })[0];

    return {
      id: trade.id,
      buyerId: trade.buyerId,
      sellerId: trade.sellerId,
      amount: trade.amount,
      currency: trade.currency,
      paymentMethod: trade.paymentMethod,
      hash: trade.hash,
      status: trade.status,
      BTCReleased: trade.BTCReleased,
      timestamp: new Date(trade.timestamp),

      buyerName: buyer.name,
      buyerPhoto: buyer.photo,
      buyerNumberOfTrades: buyer.numberOfTrades,
      buyerPositive: buyer.positive,
      buyerNegative: buyer.negative,

      sellerName: seller.name,
      sellerPhoto: seller.photo,
      sellerNumberOfTrades: seller.numberOfTrades,
      sellerPositive: seller.positive,
      sellerNegative: seller.negative,
    };
  };

  const tradeList: TradesBuyerSeller[] = map(allTrades.trades, createTrade);
  return {
    type: ActionTypes.FETCH_TRADES,
    payload: tradeList,
  };
};

export const deleteTrade = (tradeId: number): DeleteTrade => {
  history.push('/trades');
  return {
    type: ActionTypes.DELETE_TRADE,
    payload: tradeId,
  };
};

export const buyBTC = (tradeId: number): BuyBTC => {
  return {
    type: ActionTypes.BUY_BTC,
    payload: tradeId,
  };
};

export const releaseBTC = (tradeId: number): ReleaseBTC => {
  return {
    type: ActionTypes.RELEASE_BTC,
    payload: tradeId,
  };
};

export const setSelectedTrade = (tradeId: number): SetSelectedTrade => {
  return {
    type: ActionTypes.SET_SELECTED_TRADE,
    payload: tradeId,
  };
};

// messages
export const fetchMessages = (): FetchMessages => {
  const messages = map(
    allMesssages.messages,
    (msg: any): ChatMessages => {
      return {
        id: msg.id,
        tradeId: msg.tradeId,
        messageList: msg.messageList,
      };
    }
  );
  return {
    type: ActionTypes.FETCH_MESSAGES,
    payload: messages,
  };
};

export const sendMessage = (newMessage: ConstructNewMessage): SendMessage => {
  return {
    type: ActionTypes.SEND_MESSAGE,
    payload: newMessage,
  };
};

export const loadNewMessages = (tradeId: number): LoadNewMessages => {
  return {
    type: ActionTypes.LOAD_NEW_MESSAGES,
    payload: tradeId,
  };
};

// User
export const setLoggedUser = (userId: number): SetLoggedUser => {
  return {
    type: ActionTypes.SWITCH_USER,
    payload: filter(users.users, { id: userId })[0],
  };
};

export const setUserRole = (newRole: UserRole): SetUserRole => {
  return {
    type: ActionTypes.CHANGE_ROLE,
    payload: newRole,
  };
};

// Modal
export const showHideDeleteModal = (show: boolean): ShowHideDeleteModal => {
  return {
    type: ActionTypes.SHOW_HIDE_DELETE_MODAL,
    payload: show,
  };
};

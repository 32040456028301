import React from 'react';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { MenuItem } from '../../store/trades/propTypes';
import HeaderText from '../../locals/en/sellBTCHeader.json';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const sellBTCMenuItems = [
  { label: HeaderText.overview, href: '#' },
  { label: HeaderText.trades, href: '/trades' },
  { label: HeaderText.disputes, href: '#' },
  { label: HeaderText.offers, href: '#' },
  { label: HeaderText.team, href: '#' },
  { label: HeaderText.tradeHistory, href: '#' },
];

const menuList = map(sellBTCMenuItems, ({ label, href }: MenuItem, index) => {
  return (
    <Nav.Item className="nav-item" key={uuidv4()}>
      <Nav.Link className="nav-link" href={href}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );
});

const SellBitcoinsHeader = (): JSX.Element => (
  <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto ml-3">{menuList}</Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default SellBitcoinsHeader;

import { Trade, ChatMessages, UserRole, TradesBuyerSeller } from './propTypes';
import { map, filter } from 'lodash';

export const getTradeById = (tradesList: Trade[], id: number): Trade =>
  filter(tradesList, { id })[0];

export const getChatByTradeId = (
  chatMessages: ChatMessages[],
  tradeId: number
): ChatMessages => filter(chatMessages, { tradeId })[0];

export const getUserTradesList = (
  tradesList: TradesBuyerSeller[],
  userId: number,
  currentRole: UserRole
): Trade[] => {
  const filterByUserId = (trade: TradesBuyerSeller): boolean =>
    currentRole === UserRole.seller
      ? trade.buyerId === userId
      : trade.sellerId === userId;

  const filteredList = filter(tradesList, filterByUserId);

  const createTrade = (trade: TradesBuyerSeller): Trade => {
    return {
      id: trade.id,
      buyerId: trade.buyerId,
      sellerId: trade.sellerId,
      amount: trade.amount,
      currency: trade.currency,
      paymentMethod: trade.paymentMethod,
      hash: trade.hash,
      status: trade.status,
      BTCReleased: trade.BTCReleased,
      timestamp: new Date(trade.timestamp),
      userName:
        currentRole === UserRole.seller ? trade.sellerName : trade.buyerName,
      userPhoto:
        currentRole === UserRole.seller ? trade.sellerPhoto : trade.buyerPhoto,
      numberOfTrades:
        currentRole === UserRole.seller
          ? trade.sellerNumberOfTrades
          : trade.buyerNumberOfTrades,
      positive:
        currentRole === UserRole.seller
          ? trade.sellerPositive
          : trade.buyerPositive,
      negative:
        currentRole === UserRole.seller
          ? trade.sellerNegative
          : trade.buyerNegative,
    };
  };
  return map(filteredList, createTrade);
};

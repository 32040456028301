import React from 'react';
import { TradeDetailsProps, State } from '../../../store/trades/propTypes';
import { connect } from 'react-redux';
import TradeInfo from './TradeInfo';
import ChatTop from './ChatTop';
import {
  getTradeById,
  getChatByTradeId,
  getUserTradesList,
} from '../../../store/trades/selectors';

import ChatBox from './ChatBox';
import ChatInput from './ChatInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TradeDetails = (props: TradeDetailsProps): JSX.Element => {
  if (!props.trade) return <div />;

  return (
    <Row>
      <Col md={8} sm={12} className="bg-light">
        <ChatTop trade={props.trade} showModal={props.showModal} />
        <hr className="mb-1" />
        <ChatBox
          chatMessages={props.chatMessages}
          trade={props.trade}
          loggedUser={props.loggedUser}
        />
        <ChatInput
          loggedUserId={props.loggedUser.id}
          chatMessages={props.chatMessages}
          newMessages={props.newMessages}
        />
      </Col>
      <Col md={4} sm={12} className="shadow-sm">
        <TradeInfo
          trade={props.trade}
          BTCRate={props.BTCRate}
          currentRole={props.currentRole}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (
  { tradesReducer, userReducer, modalReducer }: State,
  ownProps: any
): TradeDetailsProps => {
  const tradeId = parseInt(ownProps.match.params.id);
  return {
    trade: getTradeById(
      getUserTradesList(
        tradesReducer.tradesList,
        userReducer.loggedUser.id,
        userReducer.currentRole
      ),
      tradeId
    ),
    BTCRate: tradesReducer.BTCRate,
    chatMessages: getChatByTradeId(tradesReducer.chatMessages, tradeId),
    currentRole: userReducer.currentRole,
    loggedUser: userReducer.loggedUser,
    newMessages: tradesReducer.newMessages,
    showModal: modalReducer.show,
  };
};

export default connect(mapStateToProps)(TradeDetails);

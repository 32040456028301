import React from 'react';
import { connect } from 'react-redux';
import {
  UserRole,
  State,
  TradesListProperties,
} from '../../store/trades/propTypes';
import { TradesListProps } from '../../store/trades/propTypes';
import {
  fetchBTCRate,
  fetchTrades,
  fetchMessages,
  loadNewMessages,
  setSelectedTrade,
} from '../../store/trades/actions/actions';
import Photo from './common/Photo';
import TradeStatus from './common/TradeStatus';
import { Link } from 'react-router-dom';
import { getUserTradesList } from '../../store/trades/selectors';
import history from '../../history';
import { checkForNewMessages, BTCAmount } from '../../store/trades/common';
import TradesText from '../../locals/en/trades.json';
import Figure from 'react-bootstrap/Figure';
import Media from 'react-bootstrap/Media';
import { map } from 'lodash';

class TradesList extends React.PureComponent<TradesListProps> {
  componentDidMount() {
    this.props.fetchBTCRate();
    this.props.fetchTrades();
    this.props.fetchMessages();
    const selectedTrade = history.location.pathname.split('/').pop();
    if (selectedTrade && !isNaN(parseInt(selectedTrade)))
      this.props.setSelectedTrade(parseInt(selectedTrade));
  }

  getMessageIndicator = (tradeId: number): string =>
    checkForNewMessages(this.props.newMessages, tradeId, this.props.userId)
      ? 'success'
      : 'grey';

  tradeSelectedHandler = (tradeId: number) => {
    if (
      checkForNewMessages(this.props.newMessages, tradeId, this.props.userId)
    ) {
      this.props.loadNewMessages(tradeId);
    }
    this.props.setSelectedTrade(tradeId);
  };

  buyerOrSeller = () =>
    this.props.currentRole === UserRole.seller
      ? TradesText.buyingAction
      : TradesText.sellingAction;

  selectedTrade = (tradeId: number): string =>
    this.props.selectedTrade === tradeId ? 'bg-light' : '';

  chatContent = () =>
    map(this.props.userTradesList, (myTrade) => {
      const amountInBTC = BTCAmount(myTrade.amount, this.props.BTCRate);

      return (
        <div key={myTrade.id} className={this.selectedTrade(myTrade.id)}>
          <Link to={`/trades/${myTrade.id}`} className="text-decoration-none">
            <li onClick={this.tradeSelectedHandler.bind(this, myTrade.id)}>
              <Media>
                <Media.Body>
                  <span className="text-muted">
                    <i
                      className={`ml-1 fa fa-circle text-${this.getMessageIndicator(
                        myTrade.id
                      )} pr-1`}
                      aria-hidden="true"
                    />
                    <span className="font-weight-bold">
                      {myTrade.userName} {this.buyerOrSeller()}
                    </span>
                  </span>
                  <h5 className="ml-1 mt-2 mb-0 text-dark">
                    {myTrade.paymentMethod}
                  </h5>
                  <span className="ml-1 mt-2 text-muted font-weight-light">
                    {myTrade.amount} {myTrade.currency} ({amountInBTC} BTC)
                  </span>
                </Media.Body>
                <Photo photoSrc={myTrade.userPhoto}>
                  <Figure.Caption>
                    <TradeStatus status={myTrade.status} />
                  </Figure.Caption>
                </Photo>
              </Media>
            </li>
          </Link>
          <hr className="mb-1" />
        </div>
      );
    });

  render = () => <ul className="list-unstyled">{this.chatContent()} </ul>;
}

const mapStateToProps = ({
  tradesReducer,
  userReducer,
}: State): TradesListProperties => {
  return {
    BTCRate: tradesReducer.BTCRate,
    userTradesList: getUserTradesList(
      tradesReducer.tradesList,
      userReducer.loggedUser.id,
      userReducer.currentRole
    ),
    currentRole: userReducer.currentRole,
    userId: userReducer.loggedUser.id,
    newMessages: tradesReducer.newMessages,
    selectedTrade: tradesReducer.selectedTrade,
  };
};
export default connect(mapStateToProps, {
  fetchBTCRate,
  fetchTrades,
  fetchMessages,
  loadNewMessages,
  setSelectedTrade,
})(TradesList);

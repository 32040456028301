import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import TradesList from '../../components/trades/TradesList';
import TradeDetails from '../../components/trades/tradeDetails';
import SwitchRole from '../../components/trades/SwitchRole';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TradesPage = (): JSX.Element => {
  let { url } = useRouteMatch();

  return (
    <Container>
      <SwitchRole />
      <Row>
        <Col sm={12} md={3} className="shadow-sm">
          <TradesList />
        </Col>
        <Col sm={12} md={9}>
          <Route path={`${url}/:id`} exact component={TradeDetails} />
        </Col>
      </Row>
    </Container>
  );
};

export default TradesPage;

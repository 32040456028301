import { createStore, applyMiddleware, combineReducers } from 'redux';
import tradesReducer from './trades/reducers/tradesReducer';
import userReducer from './trades/reducers/userReducer';
import modalReducer from './trades/reducers/modalReducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  tradesReducer,
  userReducer,
  form: formReducer,
  modalReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;

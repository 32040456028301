import React from 'react';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { MenuItem } from '../../store/trades/propTypes';
import HeaderText from '../../locals/en/mainHeader.json';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const mainMenuItems = [
  { label: HeaderText.buyBTC, href: '#' },
  { label: HeaderText.sellBTC, href: '#' },
  { label: HeaderText.wallet, href: '#' },
  { label: HeaderText.support, href: '#' },
  { label: HeaderText.account, href: '#' },
];

const menuList = map(mainMenuItems, ({ label, href }: MenuItem, index) => {
  return (
    <Nav.Item className="nav-item" key={uuidv4()}>
      <Nav.Link className="nav-link" href={href}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );
});

const MainHeader = (): JSX.Element => (
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Navbar.Brand href="/">
      <img alt="logo" src="../../../paxful-logo.png" width="120px" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto mr-3">{menuList}</Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default MainHeader;

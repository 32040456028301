import {
  Action,
  TradesBuyerSeller,
  TradeState,
  ChatMessages,
} from '../propTypes';
import { ActionTypes } from '../actions/actionTypes';
import { map, filter } from 'lodash';

const initialState: TradeState = {
  BTCRate: null,
  tradesList: [],
  chatMessages: [],
  newMessages: [],
  selectedTrade: 0,
};

const reducer = (
  state: TradeState = initialState,
  action: Action
): TradeState => {
  switch (action.type) {
    // BTC rates
    case ActionTypes.FETCH_BTC_RATE:
      return { ...state, BTCRate: action.payload };

    case ActionTypes.FETCH_BTC_RATE_ERR:
      return { ...state, BTCRate: action.payload };

    // Trades
    case ActionTypes.FETCH_TRADES:
      return { ...state, tradesList: action.payload };

    case ActionTypes.DELETE_TRADE:
      const omittedTradeList = filter(
        state.tradesList,
        (trade): boolean => trade.id !== action.payload
      );
      const omittedChatMessages = filter(
        state.chatMessages,
        (message): boolean => message.tradeId !== action.payload
      );
      return {
        ...state,
        tradesList: omittedTradeList,
        chatMessages: omittedChatMessages,
      };

    case ActionTypes.SET_SELECTED_TRADE:
      return { ...state, selectedTrade: action.payload };

    case ActionTypes.BUY_BTC:
      return {
        ...state,
        tradesList: map(
          state.tradesList,
          (trade): TradesBuyerSeller => {
            if (trade.id === action.payload) {
              return { ...trade, status: true };
            }
            return trade;
          }
        ),
      };

    case ActionTypes.RELEASE_BTC:
      return {
        ...state,
        tradesList: map(
          state.tradesList,
          (trade): TradesBuyerSeller => {
            if (trade.id === action.payload) {
              return { ...trade, BTCReleased: true };
            }
            return trade;
          }
        ),
      };

    // Messages:
    case ActionTypes.FETCH_MESSAGES:
      return { ...state, chatMessages: action.payload };

    case ActionTypes.SEND_MESSAGE:
      const updatedMessages = map(
        state.chatMessages,
        (msg: ChatMessages): ChatMessages => {
          if (msg.id === action.payload.chatId) {
            return {
              ...msg,
              messageList: [...msg.messageList, action.payload.messageBody],
            };
          }
          return msg;
        }
      );

      return {
        ...state,
        chatMessages: updatedMessages,
        newMessages: [
          ...state.newMessages,
          {
            tradeId: action.payload.tradeId,
            senderId: action.payload.messageBody.senderId,
          },
        ],
      };

    case ActionTypes.LOAD_NEW_MESSAGES:
      const omitLoadedMessages = filter(
        state.newMessages,
        (msg): boolean => msg.tradeId !== action.payload
      );

      return { ...state, newMessages: omitLoadedMessages };

    default:
      return state;
  }
};

export default reducer;

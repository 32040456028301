import React from 'react';
import {
  ChatInputProps,
  ConstructNewMessage,
} from '../../../store/trades/propTypes';
import { connect } from 'react-redux';
import {
  sendMessage,
  loadNewMessages,
} from '../../../store/trades/actions/actions';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { checkForNewMessages } from '../../../store/trades/common';
import TradesText from '../../../locals/en/trades.json';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

const renderMessageInput = ({ input, placeholder }: any) => {
  return (
    <FormControl {...input} placeholder={placeholder} autoComplete="off" />
  );
};

const ChatInput: React.StatelessComponent<
  ChatInputProps & InjectedFormProps<{}, ChatInputProps>
> = (props) => {
  const loadNewMessageshandler = () => {
    if (
      checkForNewMessages(
        props.newMessages,
        props.chatMessages.tradeId,
        props.loggedUserId
      )
    ) {
      props.loadNewMessages(props.chatMessages.tradeId);
    }
  };

  const onSendBtnSubmit = ({ messageInput }: any) => {
    if (messageInput && messageInput.replace(/\s+/g, '').length > 0) {
      const newMessage: ConstructNewMessage = {
        chatId: props.chatMessages.id,
        tradeId: props.chatMessages.tradeId,
        messageBody: {
          id: props.chatMessages.messageList.length + 1,
          senderId: props.loggedUserId,
          text: messageInput,
          timestamp: new Date(),
        },
      };

      props.sendMessage(newMessage);
      props.destroy();
    }
  };

  return (
    <Form onSubmit={props.handleSubmit(onSendBtnSubmit)}>
      <InputGroup className="mb-3">
        <Field
          name="messageInput"
          component={renderMessageInput}
          placeholder={TradesText.msgPlaceHolder}
          onChange={loadNewMessageshandler}
        />
        <InputGroup.Append>
          <Button id="sendBtn" variant="success" type="submit">
            {TradesText.btnSend}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

const wrapper = reduxForm<{}, ChatInputProps>({ form: 'sendMessageForm' })(
  ChatInput
);

export default connect(null, { sendMessage, loadNewMessages })(wrapper);

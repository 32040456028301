import { newMessageRecieved } from './propTypes';
import { filter } from 'lodash';
import TradesText from '../../locals/en/trades.json';

export const checkForNewMessages = (
  newMessages: newMessageRecieved[],
  tradeId: number,
  userId: number
): boolean =>
  filter(
    newMessages,
    (item) => item.tradeId === tradeId && item.senderId !== userId
  ).length > 0;

export const BTCAmount = (amount: number, rate: number | null) => {
  if (rate && !isNaN(rate)) return (amount / rate).toFixed(6);
  if (rate === null) return rate;
  return TradesText.error;
};

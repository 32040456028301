import { Action, DeleteModalState } from '../propTypes';
import { ActionTypes } from '../actions/actionTypes';

const initialState: DeleteModalState = {
  show: false,
};

const reducer = (
  state: DeleteModalState = initialState,
  action: Action
): DeleteModalState => {
  switch (action.type) {
    case ActionTypes.SHOW_HIDE_DELETE_MODAL:
      return { ...state, show: action.payload };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { TradeInfoProps, UserRole } from '../../../store/trades/propTypes';
import Photo from '../common/Photo';
import { connect } from 'react-redux';
import { buyBTC, releaseBTC } from '../../../store/trades/actions/actions';
import moment from 'moment';
import TradeStatus from '../common/TradeStatus';
import TradesText from '../../../locals/en/trades.json';
import { BTCAmount } from '../../../store/trades/common';
import Figure from 'react-bootstrap/Figure';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TradeInfo = (props: TradeInfoProps): JSX.Element => {
  const paymentHandler = () => {
    if (props.currentRole === UserRole.seller) {
      props.releaseBTC(props.trade.id);
    } else if (props.currentRole === UserRole.buyer) {
      props.buyBTC(props.trade.id);
    }
  };

  const paymentBtnText = () =>
    props.currentRole === UserRole.seller
      ? TradesText.releaseBTCBtn
      : TradesText.buyBTCbtn;

  const paymentBtnEnabled = () =>
    (props.currentRole === UserRole.seller &&
      props.trade.status &&
      !props.trade.BTCReleased) ||
    (props.currentRole === UserRole.buyer && !props.trade.status);

  if (!props.trade) return <div />;

  const myTrade = props.trade;
  const amountInBTC = BTCAmount(myTrade.amount, props.BTCRate);
  return (
    <div className="mt-3 ml-2 text-center">
      <p>
        {TradesText.tradingWith} {myTrade.userName}
      </p>
      <p className="text-muted">
        Started {moment(myTrade.timestamp).fromNow()}
      </p>
      <Button
        disabled={!paymentBtnEnabled()}
        variant="success"
        className="rounded mt-4 mb-2"
        onClick={paymentHandler}
      >
        {paymentBtnText()}
      </Button>

      <Row className="pt-4">
        <Col className="border-right my-auto">
          <Photo photoSrc={myTrade.userPhoto}>
            <Figure.Caption>
              <span className="text-success">+{myTrade.positive}</span> /{' '}
              <span className="text-danger">-{myTrade.negative}</span>
            </Figure.Caption>
          </Photo>
        </Col>
        <Col className="text-uppercase my-auto">
          <span>{TradesText.numberOfTrades}</span>{' '}
          <p>{myTrade.numberOfTrades}</p>
        </Col>
      </Row>
      <hr className="m-0" />
      <Row>
        <Col className="border-right text-uppercase">
          <span>{TradesText.tradeStatus}</span>
          <TradeStatus status={myTrade.status} />
        </Col>
        <Col className="col text-uppercase my-auto">
          <span>{TradesText.tradeHash}</span> <p>{myTrade.hash}</p>
        </Col>
      </Row>
      <hr className="m-0" />
      <Row className="h-100">
        <Col className="col border-right text-uppercase my-auto">
          <span>
            {TradesText.amount} {myTrade.currency}
          </span>{' '}
          <p>{myTrade.amount}</p>
        </Col>
        <Col className="col text-uppercase my-auto">
          <span>{TradesText.amount} btc</span> <p>{amountInBTC}</p>
        </Col>
      </Row>
      <hr className="m-0" />
    </div>
  );
};

export default connect(null, { buyBTC, releaseBTC })(TradeInfo);

import React from 'react';
import { photoProps } from '../../../store/trades/propTypes';
import Figure from 'react-bootstrap/Figure';

const Photo = (props: photoProps): JSX.Element => (
  <Figure className="figure align-self-center text-center">
    <Figure.Image
      alt="avatar"
      src={props.photoSrc}
      className="mt-2 ml-1 mr-1 rounded-circle"
      width="50"
    />
    {props.children}
  </Figure>
);

export default Photo;

import React from 'react';
import { ChatBoxProps } from '../../../store/trades/propTypes';
import { map } from 'lodash';
import Photo from '../common/Photo';
import '../../../styles/trades/chat.css';
import moment from 'moment';

class ChatBox extends React.Component<ChatBoxProps> {
  endOfChatRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidUpdate() {
    const endOfChat = this.endOfChatRef.current;
    if (endOfChat)
      endOfChat.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }

  messageList = () =>
    this.props.chatMessages ? this.props.chatMessages.messageList : [];

  clientOrUser = (senderId: number) => {
    if (senderId === this.props.loggedUser.id) {
      return {
        flexDirection: 'user-felxFirection',
        photoSrc: this.props.loggedUser.photo,
        bubble: 'user-bubble-color',
      };
    }
    return {
      flexDirection: 'client-felxFirection',
      photoSrc: this.props.trade.userPhoto,
      bubble: 'client-bubble-color ',
    };
  };

  getMsgTimestamp = (msgDate: Date): string => {
    if (moment().clone().startOf('day').isSame(msgDate, 'd'))
      return moment(msgDate).format('hh:MM a');
    if (moment().clone().subtract(1, 'days').isSame(msgDate, 'd'))
      return `Yesterday at ${moment(msgDate).format('hh:MM a')}`;
    return moment(msgDate).format('MMMM Do YY, hh:MM a');
  };

  chatContent = () =>
    map(this.messageList(), (msg) => {
      const CU = this.clientOrUser(msg.senderId);
      return (
        <div
          key={msg.id}
          className={`row chat-bubble-row m-1 ${CU.flexDirection}`}
        >
          <div>
            <Photo photoSrc={CU.photoSrc} />
          </div>
          <div className="pt-3">
            <div className={`chat-bubble m-1 ${CU.bubble}`}>
              <span>{msg.text}</span>
            </div>
            <span>
              <small className="p-2 text-muted">
                {this.getMsgTimestamp(new Date(msg.timestamp))}
              </small>
            </span>
          </div>
        </div>
      );
    });

  render = () => (
    <div className="chat-box">
      {this.chatContent()} <div ref={this.endOfChatRef} />
    </div>
  );
}

export default ChatBox;
